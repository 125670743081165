import React from 'react';

import Icon from './Icon';

export default function EmailsIcon({ className = '', text = 'Email' }: { className?: string; text?: string; }): JSX.Element {
  return <Icon text={text}>
    <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M6 6C4.89543 6 4 6.89543 4 8V16C4 17.1046 4.89543 18 6 18H18C19.1046 18 20 17.1046 20 16V8C20 6.89543 19.1046 6 18 6H6ZM7.57343 8.68076C7.12098 8.36405 6.49745 8.47408 6.18073 8.92653C5.86402 9.37898 5.97405 10.0025 6.4265 10.3192L11.4265 13.8192C11.7708 14.0603 12.2291 14.0603 12.5734 13.8192L17.5734 10.3192C18.0259 10.0025 18.1359 9.37898 17.8192 8.92653C17.5025 8.47408 16.879 8.36405 16.4265 8.68076L12 11.7793L7.57343 8.68076Z" />
    </svg>
  </Icon>;
}
